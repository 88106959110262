import { LIST_NEW_ORDERS_REQUEST, LIST_NEW_ORDERS_SUCCESS, LIST_NEW_ORDERS_FAILURE } from "../actions/listNewOrders.types";

const initialState = {
    listNewOrders: [],
    listNewOrdersError: "",
    listNewOrdersLoading: false,
    searched: false
}

const ListNewOrders = (state=initialState,action) => {
    state={...state}

    switch (action.type) {
        case LIST_NEW_ORDERS_REQUEST:
            state.listNewOrdersLoading = true
            break;
        case LIST_NEW_ORDERS_SUCCESS:
            state.listNewOrdersLoading = false
            state.listNewOrders=action.payload
            state.searched=true
            break;
        case LIST_NEW_ORDERS_FAILURE:
            state.listNewOrdersLoading = false
            state.listNewOrdersError = action.payload
            break;
        default:
            break;
    }

    return state
}

export default ListNewOrders
import React, { useEffect } from "react";
import { Sidebar, Menu, Grid } from "semantic-ui-react";
import LeftNavitem from "./LeftNavitem";
import { leftNav } from "./LeftNavData";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const LeftNav = ({ nbrNewOrders, nbrInProcess }) => {
  leftNav[0].nbrOrders = nbrNewOrders;
  leftNav[1].nbrOrders = nbrInProcess;
  useEffect(() => {});
  return (
    <Grid.Column
      as={Menu}
      animation="push"
      icon="labeled"
      inverted
      width={3}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1
        // height: "103vh",
        // bottom: 0
        
        // position: "absolute",
        // right: 0,
        // minHeight: "100vh",
      }}
    >
      <div style={{ width: "60%", padding: 5, }}>
        <Grid.Row columns={1}>
          {leftNav.map((item, i) => (
            <LeftNavitem
              key={i}
              title={item.title}
              img={item.img}
              link={item.link}
              color={item.color}
              nbr={item.nbrOrders}
            />
          ))}
        </Grid.Row>
      </div>
    </Grid.Column>
  );
};

const mapStateToProps = (state) => {
  return {
    newOrders: state.lnor.listNewOrders,
  };
};
export default connect(mapStateToProps)(withRouter(LeftNav));

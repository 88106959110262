import React, { useState } from 'react'
import { Modal, Input, Grid, Checkbox, Button, Form } from 'semantic-ui-react'
// import ordonnance from "../../img/ordonnance.png"
import ReactImageZoom from 'react-image-zoom';
import { connect } from 'react-redux';
import { Draggable } from "react-drag-reorder";




const CustomModal = ({trigger,code,urlImage, socket,pharmacien,idCommande}) => {
  let [loading, setLoading] = useState(false)
  let [state,setState] = useState({
    inputs: [
      {
        [`inp-0-${code}-Check`]: false, 
        [`inp-0-${code}-Price`]: "", 
        [`inp-0-${code}-Name`]: "", 
      },
      {
        [`inp-1-${code}-Check`]: false, 
        [`inp-1-${code}-Price`]: "", 
        [`inp-1-${code}-Name`]: "", 
      }
    ]
  })
  

  let handleInputAdd =  (nbr,code) => {
    nbr = nbr+1
    let data= {
      [`inp-${nbr}-${code}-Check`]: false, 
      [`inp-${nbr}-${code}-Price`]: "", 
      [`inp-${nbr}-${code}-Name`]: "", 
    }

    setState({inputs: [...state.inputs,data]})
  }

  let handleInputDrop = () => {
    
      let {inputs} = state
      if(inputs.length >1){
        inputs.pop()
      }
      setState({inputs})
    
  }

let handleInputChange = ({target}) => {
  let {inputs} = state
  let index = target.name.split("-")[1];
  inputs[index][target.name] = target.value
  setState({inputs})
}

let handleCheckboxChange = ({target}) => {
  let {inputs} = state
  let index = target.id.split("-")[1];


  inputs[index][target.id] = !inputs[index][target.id];
  setState({inputs})

}

  let _input =  (item=0,index) => {
    const nom= `inp-${index}-${code}-Name`;
    const prix= `inp-${index}-${code}-Price`
    const check= `inp-${index}-${code}-Check`
    
    let defaultStyle = {border: "1px solid #757575", borderRadius:5}
    return (
    <div key={index} style={{display: "flex",marginTop: 10}}>       
      <Input 
          placeholder="Nom médicament" 
          style={defaultStyle} 
          name={nom} 
          required
          onChange={handleInputChange}
          value={state.inputs[index][nom]}
      />
      <Input 
        placeholder="Prix" 
        style={{...defaultStyle,marginLeft: 10}} 
        type="number" 
        name={prix} 
        required={index===0} 
        onChange={handleInputChange}
        value={state.inputs[index][prix]}
        
      />
      <Checkbox 
        style={{marginTop: 10,marginLeft: 10}} 
       
        checked={state.inputs[index][check]}
        onChange={handleCheckboxChange}
        id={check}
      />
      <div style={{marginLeft: 10}}>
        {
        index===state.inputs.length -1 &&  (
        <>
        <Button circular icon='plus' style={{backgroundColor:"#4CC531",color:"white"}} onClick={() => handleInputAdd(index,code)} />
        {index>0 &&<Button circular icon='times' style={{backgroundColor:"red",color:"white"}} onClick={handleInputDrop} />}
          </>
          )}
      </div>
    </div>
      
  )}

  let handleSubmit =e => {
    e.preventDefault();
    setLoading(true)
    let {inputs} = state
    let myData = inputs.filter(inp => {
      let keysArray = Object.keys(inp);
      return keysArray[0].includes(code) 
    })
    let dataToSend = []

    myData.forEach((data,i) => dataToSend.push({
      nom: data[`inp-${i}-${code}-Name`],
      prixUnitaire: data[`inp-${i}-${code}-Price`],
      disponiblite: data[`inp-${i}-${code}-Check`],
      qte: 1
    }))
    socket.emit("do_inventory",
    {
      idPharmacie:pharmacien.idPharmacie,
      _id: idCommande,
      medicament: dataToSend
    },(response) => {
      
    }
    )
    setTimeout(() => {
      window.location.reload(false)
    }, 2000);
  }



  const props = {height: 500, img: urlImage,zoomPosition:"left",scale: 0.8,zoomWidth:500,width: 400};
  
  return (
  <Modal trigger={trigger} size="large">
    <Modal.Header>Commande {code}</Modal.Header>
    
      <Modal.Content image >
        <Grid columns={2} container>
          <Grid.Row>
           
            <Grid.Column width={"seven"}>
              <ReactImageZoom {...props}   />
              {/* <Image source={urlImage} /> */}
            </Grid.Column>
            <Grid.Column width="nine">
              <Modal.Description>
           
              <p style={{color:"#757575", fontSize: 20,textAlign: "center", fontWeight: "bold"}}>Entrez les noms et prix des médicaments sur la photo et cochez ceux disponibles</p >
              <Form onSubmit={handleSubmit}>
                <div >
              {/* <Draggable > */}
                {state.inputs.map((item,i) =><div key={i}>{ _input(state.inputs.length+1,i)}</div>)}
                {/* </Draggable> */}

                </div>
                <div style={{textAlign:"center", marginTop: 30}}>
                  {/* <Link to="/en-traitement"> */}
                    <Button 
                      size="big" 
                      style={{backgroundColor:"#6031C5",color:"white",fontWeight:"bold"}} 
                      type="submit"
                      loading={loading}

                      >Valider</Button>
                  {/* </Link> */}
                  <Button size="big"style={{backgroundColor:"#18A0FB",color:"white",fontWeight:"bold"}} type="button">Tous indisponibles</Button>

                </div>
              </Form>
              </Modal.Description>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
   
  </Modal>
)
}


const mapStateToProps = state => {
  return {
    socket: state.ar.socket,
    pharmacien: state.ar.pharmacien
  }
}
export default connect(mapStateToProps)(CustomModal)
import {put, takeEvery} from "redux-saga/effects"
import {LIST_NEW_ORDERS} from "../actions/listNewOrders.types"
import { listNewOrdersRequest, listNewOrdersSuccess, listNewOrdersFailure } from "../actions/listNewOrders.actions"
import Axios from "axios";

function* executeListNewOrders (action){
    try{
        const idPharmacie=action.payload
    
        yield put(listNewOrdersRequest());
        const result = yield Axios.get(`https://service-pharmacien-wergu-ewafb.ondigitalocean.app/pharmacien/commande/lister-nouvelles-commandes/${idPharmacie}`)
        yield put(listNewOrdersSuccess(result.data))
        // console.log(res)
    }catch(e){
        yield put(listNewOrdersFailure(e.message))
        alert("Une erreur est survenue. Verifiez votre connexion et réessayez. Si l'erreur persiste, signalez la!")
    }

}


export function* listNewOrdersWatcher () {
    yield takeEvery(LIST_NEW_ORDERS,executeListNewOrders)
}


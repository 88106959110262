import socketIOClient from "socket.io-client";
import { SET_NEW_NOTIFICATIONS } from "../actions/app.types";

const p = localStorage.getItem("pharmacien") 
const initialState = {
    socket: socketIOClient("https://service-commande-wergu-82j87.ondigitalocean.app", {secure: true}),
    newNotifications: [],
    pharmacien: JSON.parse(p)
}

// console.log("actualPharm",initialState.pharmacien)
const AppReducer = (state=initialState,action) => {
    state={...state}

    switch (action.type) {
        case SET_NEW_NOTIFICATIONS:
            state.newNotifications = action.payload
            break;
    
        default:
            break;
    }

    return state
}

export default AppReducer
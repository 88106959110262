import React, { useEffect } from 'react';
import {connect} from 'react-redux'
import {setActualPath} from "../actions/actualPath.actions"
import { withRouter, Redirect } from 'react-router-dom';
import { Form, Input, Button, Image, Message } from 'semantic-ui-react';
import logo from "../img/logo2.png"
import { login, changeNumTel, changePassword } from '../actions/auth.actions';

const Login = ({history,setActualPath,login: log,numTel,password,changeNumTel,changePassword,authLoading,authFailureMessage,authData,pharmacien}) => {
    let {pathname} = history.location    

    useEffect(() => {
       
        if(authData && authData._id){
            localStorage.setItem("pharmacien",JSON.stringify(authData))
            window.location.reload(false)
        }
    })

    useEffect(() => {
        setActualPath(pathname)
    },[pathname,setActualPath,log])
    
    let handleSubmit = () => {
        let removedSpaces = numTel.replace(/\s/g, '')
        log({numTel: removedSpaces, password})
    }
    const p =localStorage.getItem('pharmacien')
    if(pharmacien) {
        return(
            <Redirect to="/" />
        )
    }else
    return (
        <div style={{width: "100%", minHeight:"100vh",backgroundColor:"#18A0FB", alignItems:"center",justifyContent:"center",display:"flex"}}>
            <Form style={{display:"flex",flexDirection: "column"}} onSubmit={handleSubmit} >
                <Image src={logo} size="large" />
                {authFailureMessage && <Message negative>
                    <Message.Header>Echec de connexion !</Message.Header>
                    <p>
                    <b>Numéro de téléphone</b> ou <b>mot de passe</b> incorrect.
                    </p>
                </Message>}
                {authData && authData._id && <Message positive>
                    <Message.Header>Connexion réussie !</Message.Header>
                    <p>
                    Vous serez redirigé sous peu vers votre espace.
                    </p>
                </Message>}
                

                <Input placeholder="Votre numéro de téléphone ..." size="massive" value={numTel} type="text" onChange={({target}) => changeNumTel(target.value)} required />
                <Input placeholder="Votre mot de passe ..." size="massive" type="password" value={password} onChange={({target}) => changePassword(target.value)} style={{marginTop: 50}} required />
                <div style={{textAlign:"center", marginTop: 50}}>
                
                        <Button size="massive" loading={authLoading} inverted type="submit" >Se connecter</Button>
           
                </div>
            </Form>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        numTel: state.aur.numTel,
        password: state.aur.password,
        authLoading: state.aur.authLoading,
        authFailureMessage: state.aur.authFailureMessage,
        authData: state.aur.authData,
        pharmacien: state.ar.pharmacien
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setActualPath: (val) => dispatch(setActualPath(val)),
        login: val => dispatch(login(val)),
        changeNumTel: val => dispatch(changeNumTel(val)),
        changePassword: val => dispatch(changePassword(val))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Login));
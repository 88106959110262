import React from 'react';

const Message = ({isClient=true, label}) => {
    return (
        <div style={{display: "inline-block",minWidth: 150,maxWidth: 450 ,backgroundColor: isClient ? "#F1F2F4" : "#12A85C", borderBottomRightRadius: 20,borderTopLeftRadius: 20, padding: 10, alignSelf: isClient ?"flex-start" : "flex-end", marginBottom: 20}}>
            <p style={{fontSize: 20, color: isClient ? "#000000": "#ffffff"}}>{label}</p>
        </div>
    );
};

export default Message;
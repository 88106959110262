import React from "react";
import { Segment, Menu, Button, Grid } from "semantic-ui-react";
// let handleItemClick = () => {

// }
const TopNav = ({ statusConnection }) => {
  // let activeItem="home"
  return (
    <Grid>
      <Grid.Column as={Segment} inverted style={{ backgroundColor: "#18A0FB" }}>
        <Menu inverted secondary>
          {/* <Menu.Item
            name='home'
            active={activeItem === 'home'}
            onClick={handleItemClick}
            />
          <Menu.Item
            name='messages'
            active={activeItem === 'messages'}
            onClick={handleItemClick}
            /> */}

          <Menu.Item position="left">
            <Button inverted>{statusConnection}</Button>
          </Menu.Item>
          {/* <Menu.Item as="p" style={{display: "flex"}}>
            
                <p>{statusConnection}</p>
              </Menu.Item> */}

          <Menu.Item position="right">
            <Button
              onClick={() => {
                localStorage.removeItem("pharmacien");
                window.location.reload(false);
              }}
              inverted
            >
              Se déconnecter
            </Button>
          </Menu.Item>
        </Menu>
      </Grid.Column>
    </Grid>
  );
};

export default TopNav;

import {all,fork} from "redux-saga/effects"
import { listNewOrdersWatcher } from "./listNewOrders.sagas"
import { authWatcher } from "./auth.sagas"


export default function* rootSaga() {
    yield all([
        fork(listNewOrdersWatcher),
        fork(authWatcher),
    ])
}
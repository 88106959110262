import { SET_ACTUAL_PATH } from "../actions/actualPath.types";

const initialState = {
    actualPath: null
}

const ActualPathReducer = (state=initialState,action) => {
    state ={...state}

    switch (action.type) {
        case SET_ACTUAL_PATH:
            state.actualPath = action.payload
            break;
    
        default:
            break;
    }


    return state;
}

export default ActualPathReducer
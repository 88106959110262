import React from 'react';
import { Button, Divider, Grid } from 'semantic-ui-react';

const ChatClient = () => {
    return (
        <div>
            <Grid as={Button} style={{width: "100%",}}>
                <Grid.Column width={4}>
                     <div style={{border: "solid black 1px", width: 50,height: 50, backgroundColor: "white", borderRadius: 50}} />

                </Grid.Column>
                <Grid.Column width={10} style={{}} >
                    <span style={{fontSize: 16, fontWeight: "bold"}}>3WXRDA</span>
                    <p>Aussi je voulais de...</p>
                </Grid.Column>
                <Grid.Column width={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div
            style={{
              width: 20,
              height: 20,
              backgroundColor: "green",
              position: "absolute",
            left: 0,
              borderRadius: 20,
              alignItems: "center",
              justifyContent: "center",
              display: "flex"
            
            }}
          >
              <p style={{fontSize: 12, fontWeight: "bold", color: "white", marginBottom: 1, marginRight: 2}}>6</p>
          </div>
                </Grid.Column>
            </Grid>
            <Divider/>
        </div>
    );
};

export default ChatClient;

import {SET_NOTIFICATION} from "./notification.types"


//Permet de savoir s'il y a une nouvelle notif (true ou false)
export const setNotification = (val) => {
    return {
        type: SET_NOTIFICATION,
        payload: val
    }
}
import medoc from "../../img/medoc.png"
import nouvelles_commandes from "../../img/nouvelles_commandes.png"
import en_traitement from "../../img/en_traitement.png"
import pretes from "../../img/pretes.png"
import chat from "../../img/chat.png"

export const leftNav = [
    {
        title: "Chat",
        img: chat,
        link: "/chat",
        color:"#6031C5"
    },
    {
        title: "Nouvelles commandes",
        img: nouvelles_commandes,
        link: "/",
        color:"#46C531"
    },
    {
        title: "Commandes en cours de traitement",
        img: en_traitement,
        link: "/en-traitement",
        color:"#6031C5"
    },
    {
        title: "Commandes prêtes pour la livraison",
        img: pretes,
        link: "/pretes",
        color:"#C5316F"
    },
    
    {
        title: "Gestion des médicaments",
        img: medoc,
        link: "/gestion-medocs",
        color:""
    },
]


import React, { useEffect } from 'react';
import ListePretes from '../components/ListeCommandes/ListePretes';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setActualPath } from '../actions/actualPath.actions';

const Pretes = ({setActualPath,history}) => {
    let {pathname} = history.location
    useEffect(() => {
        setActualPath(pathname)
    },[pathname,setActualPath])
    return (
        <div style={{width: "100%"}}>
            {/* <ListePretes/> */}
        
            <h1 style={{textAlign:"center",marginTop: 200}}>En cours de construction ! </h1>
      
        </div>
    );
};



const mapDispatchToProps = dispatch => {
    return{
        setActualPath: (val) => dispatch(setActualPath(val))
    }
}

export default connect(null,mapDispatchToProps)(withRouter(Pretes));
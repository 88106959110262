import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, CHANGE_NUM_TEL, CHANGE_PASSWORD } from "../sagas/auth.types";

const initialState = {
    authLoading: false,
    authFailureMessage: "",
    authData: {},
    numTel: "",
    password: ""
}   

const AuthReducer = (state=initialState, action) => {
    state = {...state}

    switch (action.type) {
        case LOGIN_REQUEST:
            state.authLoading = true
            
            break;
        case LOGIN_SUCCESS:

            state.authLoading = false
            state.authData =action.payload;
            state.authFailureMessage = "";

            break;
        case LOGIN_FAILURE:

            state.authLoading=false;
            state.authFailureMessage = action.payload;
            break;
        case CHANGE_NUM_TEL:
            state.numTel = action.payload
            break;
        case CHANGE_PASSWORD:
            state.password = action.payload
            break;
        default:
            break;
    }

    return state;
}

export default AuthReducer;
import { LOGIN, CHANGE_PASSWORD, CHANGE_NUM_TEL } from "../sagas/auth.types";

export function login(val){
    return {
         type: LOGIN,
         payload: val
     }
 }

 export const changeNumTel = (val) =>{
    return {
         type: CHANGE_NUM_TEL,
         payload: val
     }
 }


 export const changePassword = (val) => {
    return {
         type: CHANGE_PASSWORD,
         payload: val
     }
 }
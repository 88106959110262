import { LIST_NEW_ORDERS,LIST_NEW_ORDERS_REQUEST,LIST_NEW_ORDERS_SUCCESS,LIST_NEW_ORDERS_FAILURE } from "./listNewOrders.types"

export const listNewOrders = (val) => {
    return {
        type: LIST_NEW_ORDERS,
        payload: val
    }
}

export const listNewOrdersRequest = () => {
    return {
        type: LIST_NEW_ORDERS_REQUEST
    }
}

export const listNewOrdersSuccess = (val) => {
    return {
        type: LIST_NEW_ORDERS_SUCCESS,
        payload: val
    }
}

export const listNewOrdersFailure = (err) => {
    return {
        type: LIST_NEW_ORDERS_FAILURE,
        payload: err
    }
}
import { SET_NOUVELLES_COMMANDES } from "../actions/nouvellesCommandes.types";

const initialState = {
    nouvellesCommandes: []
}

const nouvellesCommandesReducer = (state=initialState,action) => {
    state = {...state}

    switch (action.type) {
        case SET_NOUVELLES_COMMANDES:
            state.nouvellesCommandes = action.payload
            break;
    
        default:
            break;
    }



    return state
}

export default nouvellesCommandesReducer
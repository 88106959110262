import React from 'react';
import { Grid } from 'semantic-ui-react';


const Commande = ({ middle, right, code, idUser }) => {
    return (
        <div style={{display: "flex", justifyContent: "center"}}>
        <div style={{ backgroundColor: "white", marginTop: 50, borderRadius: 10, border: "solid #757575 1px", width: "80%", }}>
            <Grid columns={3}>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 120 }}>
                            <div>
                                <p style={{
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    color: "#5A5A5A",
                                    textAlign: "center"
                                }}>
                                    {code}
                                </p>
                            </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 120, width: "100%"}}>
                            <div style={{display: "flex", width: "100%",justifyContent: "space-around"}}>
                                {middle}
                            </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 120 }}>
                            <div>
                                {right}
                            </div>
                        </div>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </div>
        </div>

        // <Grid.Row columns={1} centered >
        //     <Grid.Column >
        //         <div style={{
        //             width: "100%",
        //             //marginTop: 30,
        //             height: 120,
        //             borderRadius: 10,
        //             //boxShadow: "4px 4px 4px 2px rgba(0, 0, 0, 0.25)",

        //             //boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
        //             backgroundColor:"white",
        //             border: "solid #757575 1px",
        //             backgroundColor: "green"
        //         }}>
        //     <Grid columns={3}>


        //                 <Grid.Row centered style={{ backgroundColor: "white"}} >

        //                     <Grid.Column style={{justifyContent: "center", alignItems: "center", display: "flex"}} >


        //                                 <div>
        //                         <p style={{
        //                             fontSize: 25,
        //                             fontWeight: "bold",
        //                             color: "#5A5A5A",
        //                             textAlign: "center"
        //                         }}>
        //                            {code}
        //                                 </p>
        //                                 </div>
        //                     </Grid.Column>
        //                     <Grid.Column  style={{justifyContent: "center", alignItems: "center", display: "flex", backgroundColor: "red"}}>
        //                         <div>
        //                        {middle}
        //                        </div>
        //                     </Grid.Column>
        //                     <Grid.Column >
        //                         {right}
        //                     </Grid.Column>
        //                 </Grid.Row>


        //         </Grid>
        //         </div>
        //     </Grid.Column>
        // </Grid.Row>

    );
};

export default Commande;
import React from 'react';
import ScrollArea from "react-scrollbar"
import { Button } from 'semantic-ui-react';
import Commande from './Commande';
import { Link } from 'react-router-dom';


const ListeEnTraitement = ({enTraitement}) => {
    const items = enTraitement
    // console.log(items)
    return (
        <ScrollArea
                speed={0.8}
                className="area"
                contentClassName="content"
                horizontal={false}
                style={{ height: 600, marginTop: 50, backgroundColor: " #e2e1e0", width: "98%" }}
            >
            {items.map((item,i) => <Commande
                key={i}
                code={item.code}
                middle={
                    <>
                    {/* <p style={{textAlign: "center", fontSize: 20, marginTop: 10}}>Confirmation réussie ! <br/>
                        Préparez la commande</p> */}
                    </>
                }

                right={
                    <Link to='/pretes'>
                        <Button disabled style={{ backgroundColor: "#C5316F", color: "white", marginRight: 20 }} size="large" >Prête pour la livraison</Button>  
                    </Link>
                }
            />)}
            
          
         
          
        </ScrollArea>
    );
};

export default ListeEnTraitement;
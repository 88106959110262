import React, { useEffect, useState } from 'react';
import ListeNouvellesCommandes from '../components/ListeCommandes/ListeNouvellesCommandes';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setActualPath } from '../actions/actualPath.actions';
import { Loader } from 'semantic-ui-react';
//import { listNewOrders } from '../actions/listNewOrders.actions';



const NouvellesCommandes = ({history,setActualPath,pharmacien,newOrders,searched, socket}) => {
    let {pathname} = history.location
    let [loading, setLoading]=useState(false)
    let [listNewOrders, setListNewOrders] = useState([])
    useEffect(() => {
        setLoading(true)
        socket.emit("get_new_commands",{idPharmacie:pharmacien.idPharmacie},async(response,message) => {
            setLoading(false)
           setListNewOrders(response)
        })
    },[pharmacien,socket])

    useEffect(() => {
        setActualPath(pathname)
      //listNewOrders(pharmacien.idPharmacie)
        
       
    },[pathname,setActualPath,listNewOrders,pharmacien, setLoading, setListNewOrders])
    return (
        <div style={{ width:"100%"}}>
            <div>
                    {/* {loading && <p style={{textAlign: "center", fontSize: 30}}> Vous n'avez pas de nouvelles commandes</p>} */}
            </div>
                    {loading && <Loader content='Chargement des nouvelles commandes' style={{fontSize: 30}} size="large" active/>}
            <ListeNouvellesCommandes nouvellesCommandes={listNewOrders}/>
            
        </div>
      
    );
};

const mapStateToProps = state => {
    return {
        socket: state.ar.socket,
        newOrders: state.lnor.listNewOrders,
        searched: state.lnor.searched,
        pharmacien: state.ar.pharmacien
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setActualPath: (val) => dispatch(setActualPath(val)),
       // listNewOrders: val => dispatch(listNewOrders(val))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(NouvellesCommandes));
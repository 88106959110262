import React from 'react';
import { connect } from 'react-redux';
import { setNotification } from '../actions/notification.actions';
import { setNewNotifications } from '../actions/app.actions';
import { listNewOrders } from '../actions/listNewOrders.actions';

const Notification = ({ setNotification,socket, newNotifications,pharmacien,listNewOrders }) => {

    let handleNewNotifications = () => {
        // console.log("clicked notifications",newNotifications)
        newNotifications.map(commande => 
        // listNewOrders({idPharmacie: pharmacien.idPharmacie,idCommande: commande._id}))

            socket.emit("change_status",
            {
                idPharmacie: pharmacien.idPharmacie,
                _id: commande._id,
                status: "received"
            }
            ))
            
        
                window.location.reload(false)
                
        }

    return (
        <div onClick={()=>{
            setNotification(false)
            handleNewNotifications()
            
        }} 
        style={{ width: "100%", height: "100vh", backgroundColor: "#4CC531", display: "flex", justifyContent: "center", alignItems: "center",cursor: "pointer" }} >
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ width: 200, height: 200, borderRadius: 400, backgroundColor: "#2D9017", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div >
                        <p style={{ textAlign: "center", color: "white", fontSize: 70 }}>{newNotifications.length}</p>
                    </div>
                </div>
                <div style={{ display: "flex", marginTop: 20 }}>
                    <p style={{ fontSize: 40, textAlign: "center", color: "white" }}>Nouvelle(s) commande(s)</p>

                </div>
                <div>
                    <p style={{ fontSize: 30 }}>Cliquez n'importe où</p>
                </div>

            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        socket: state.ar.socket,
        newNotifications: state.ar.newNotifications,
        pharmacien: state.ar.pharmacien
    }
}

const mapDispatchToProps = dispatch => {
    return {
      setNotification: val => dispatch(setNotification(val)),
      setNewNotifications: val => dispatch(setNewNotifications(val)),
      listNewOrders: val=> dispatch(listNewOrders(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
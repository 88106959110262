import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom"
import GestionMedicaments from './containers/GestionMedicaments';
import LeftNav from './components/LeftNav/LeftNav';
import { Grid } from 'semantic-ui-react';
import NouvellesCommandes from './containers/NouvellesCommandes';
import Pretes from './containers/Pretes';
import EnTraitement from './containers/EnTraitement';
import Login from './containers/Login';
import { connect } from 'react-redux';
import TopNav from './components/TopNav/TopNav';
import sound from "./sounds/GameOfThronesMarimbaRemix.mp3"
import Notification from './containers/Notification';
import { setNotification } from './actions/notification.actions';
import { setNewNotifications } from './actions/app.actions';
import Chat from './containers/Chat';




function App({ actualPath, isNotified, setNotification, socket, setNewNotifications, pharmacien }) {
  const [audio] = useState(new Audio(sound))
  const [statusConnection, setStatusConnection] = useState("déconnecté")
  const [nbrNewOrders, setNbrNewOrders] = useState("?")
  const [nbrInProcess, setNbrInProcess] = useState("?")
  let showNav = (actualPath !== null && actualPath.toLowerCase() !== "/login" && !isNotified && pharmacien)

  let startAudio = () => {
    audio.loop = true;

    audio.play()
      .catch(err => {
        //audio.autoplay = true
        audio.play()
      })
  }

  let stopAudio = () => {
    audio.pause();
    audio.currentTime = 0;
  }
  const count = useRef(0)
  useEffect(() => {

    setInterval(() => {
      if (pharmacien) {
        socket.emit("get_new_commands", { idPharmacie: pharmacien.idPharmacie }, async (response) => {
          let resp = await response
          setNbrNewOrders(resp.length)
          console.log(resp.length);
          if(resp.length === 0){
            count.current+=1

          }
          if(resp.length===0 && count.current >20){
            setNotification(false)
            count.current=0

          }
        })


        socket.emit("get_commands_in_process", { idPharmacie: pharmacien.idPharmacie }, async (response) => {
          let resp = await response
          setNbrInProcess(resp.length)
        })

      }

    }, 2000);

  }, [socket, pharmacien, nbrNewOrders])

  useEffect(() => {

    socket.on('disconnect', () => {
      //socket.reconnection(true)
      console.log("disconnected")
      setStatusConnection("déconnecté")

    })

    socket.on('connect', () => {
      console.log("connected")
      setStatusConnection("connecté")
      //Demander les nouvelles commandes qui me sont destinés  (ecoutés sur receve_ordonnance)
      socket.emit("new_notif");


    })

    socket.on("reconnect", () => {
      console.log("reconnected")
      setStatusConnection("reconnecté")

    })

    socket.on("reconnecting", () => {
      setStatusConnection("reconnexion en cours")
    })

    socket.on("receve_ordonnance", (data = []) => {

      if (data && data.length && pharmacien) {
        //Verifier qu'il m'appartient et si oui faire le traitement ci-dessous
        let myOrders = []
        data.forEach(order => {
          order.listePharmaProche.forEach(pharma => {

            //Verifier si le pharmacien connecté est parmi la liste des pharma proche(listePharmaProche)
            if (pharma.idPharmacie === pharmacien.idPharmacie) {
              myOrders.push(order)
            }
          })
        })

        if (myOrders.length > 0) {
          // console.log("my-orders")
          setNewNotifications(myOrders)
          console.log(myOrders)
          myOrders = []
          setNotification(true)
        } else {
          setNotification(false)
        }


      }
      //appel à change_status lorsqu'il clique sur la notification
      //Emit et listen de lister_nouvelles_commandes et setState

    })

  }, [setNotification, socket, setNewNotifications, pharmacien])

  let notified = false
  //https://service-commande-wergu-dot-valued-host-210410.appspot.com
  useEffect(() => {
    if (isNotified && isNotified !== notified) {
      startAudio()
      notified = isNotified
    } else {
      stopAudio()
      // setNotification(false)
    }
  })



  let _renderPage = (Page) => {
    return pharmacien ? Page : Login;
  }



  return (

    <BrowserRouter>
      <Grid style={{ minHeight: "100vh" }}>
        {/* <Grid.Row  style={{width:"100%",height:"100%"}} columns={showNav ? 2 : 1} > */}

        {showNav && 
          <LeftNav
            nbrInProcess={nbrInProcess}
            nbrNewOrders={nbrNewOrders} />
        }

        <Grid.Column width={showNav ? 13 : 16} >
          {showNav && <TopNav statusConnection={statusConnection} />}

          <Switch>
            <Route exact path="/" component={_renderPage(isNotified ? Notification : NouvellesCommandes)} />
            <Route exact path="/pretes" component={_renderPage(isNotified ? Notification : Pretes)} />
            <Route exact path="/en-traitement" component={_renderPage(isNotified ? Notification : EnTraitement)} />
            <Route exact path="/gestion-medocs" component={_renderPage(isNotified ? Notification : GestionMedicaments)} />
            <Route exact path="/chat" component={_renderPage(isNotified ? Notification : Chat)} />
            <Route exact path="/login" component={Login} />
          </Switch>
        </Grid.Column>
        {/* </Grid.Row> */}
      </Grid>
    </BrowserRouter>


  );
}

const mapStateToProps = state => {
  return {
    actualPath: state.apr.actualPath,
    isNotified: state.nr.isNotified,
    socket: state.ar.socket,
    newNotifications: state.ar.newNotifications,
    pharmacien: state.ar.pharmacien
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNotification: val => dispatch(setNotification(val)),
    setNewNotifications: val => dispatch(setNewNotifications(val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React, { useEffect } from 'react';
import {connect} from 'react-redux'
import {setActualPath} from "../actions/actualPath.actions"
import { withRouter } from 'react-router-dom';


const GestionMedicaments = ({history,setActualPath}) => {
    let {pathname} = history.location
    useEffect(() => {
        setActualPath(pathname)
    },[pathname,setActualPath])
    return (
        <>
            <h1 style={{textAlign:"center",marginTop: 200}}>En cours de construction ! </h1>
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return{
        setActualPath: (val) => dispatch(setActualPath(val))
    }
}

export default connect(null,mapDispatchToProps)(withRouter(GestionMedicaments));
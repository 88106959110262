import React, { useEffect, useState } from 'react';
import { setActualPath } from "../actions/actualPath.actions"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Icon, Input, Menu } from 'semantic-ui-react';
import ChatClient from '../components/ChatClient';
import Message from '../components/Message';
import Pusher from "pusher-js"


// let pusher = new Pusher("08fc4f1dc9b1eec49378", {
//     cluster: "mt1",
//     authEndpoint: "https://service-user-wergu.herokuapp.com" + "/user/pusher/auth",
// })

// let channel = pusher.subscribe("private-wergu-chat")


const Chat = ({ history, setActualPath }) => {



    let { pathname } = history.location

    const [subscribed, setSubscribed] = useState(false)
    let [myMessages, setMyMessages] = useState([])
    const [inputData, setInputData] = useState("")
    const [channel, setChannel] = useState(null)

    useEffect(() => {
        setActualPath(pathname)
    }, [pathname, setActualPath])
    let messages = [
        {
            text: "Hello",
            isClient: true
        },
        {
            text: "Oui bonjour",
            isClient: false
        },
        {
            text: "Vous allez bien ?",
            isClient: true
        },
        {
            text: "Oui, et vous ?",
            isClient: false
        },
        {
            text: "Pareil, En fait je voulais demander si le paracetamol xxx est disponible dans votre pharmacie actuellement ?",
            isClient: true
        },
        {
            text: "Oui il est dispo",
            isClient: false
        },
        {
            text: "Hello",
            isClient: true
        },
        {
            text: "Oui bonjour",
            isClient: false
        },
        {
            text: "Vous allez bien ?",
            isClient: true
        },
        {
            text: "Oui, et vous ?",
            isClient: false
        },
        {
            text: "Pareil, En fait je voulais demander si le paracetamol xxx est disponible dans votre pharmacie actuellement ?",
            isClient: true
        },
        {
            text: "Oui il est dispo",
            isClient: false
        },


    ]

    useEffect(() => {
        console.log("hereiiiiii");
    }, [])
    // if (!subscribed) {
    //     console.disableYellowBox = true;
    //     channel.bind("pusher:subscription_succeeded", () => {
    //         setSubscribed(true)
    //     })

    //     pusher.bind("client-new-message", (d) => {
    //         setMyMessages(old => [...old, {
    //             id: Date.now().toString(),
    //             text: d.message,
    //             isMe: Math?.random() > 0.5 ? true : false,
    //             date: new Date().toLocaleTimeString("fr-FR").substring(0, 5)
    //         }])

    //         console.log(myMessages);
    //         console.log("pushhhhhhhhhh", d);
    //     })
    // }

    const addMessage = (d) => {
        // let mess = [...myMessages]

        // mess.push({
        //     id: myMessages.length.toString(),
        //     text: "jjj", //d.message,
        //     isMe: Math?.random() > 0.5 ? true : false,
        //     date: new Date().toLocaleTimeString("fr-FR").substring(0, 5)
        // })
        // console.log(mess);

        setMyMessages(old => [...old, {
            id: old.length.toString(),
            text: d.message,
            isMe: false,
            date: new Date().toLocaleTimeString("fr-FR").substring(0, 5)
        }])

    }
    useEffect(() => {
        const pusher = new Pusher("08fc4f1dc9b1eec49378", {
            cluster: "mt1",
            authEndpoint: "https://service-user-wergu-8ye5y.ondigitalocean.app" + "/user/pusher/auth"
        })
        
        const channel = pusher.subscribe("private-wergu-chat")
        channel.bind("pusher:subscription_succeeded", () => {
            setSubscribed(true)
            setChannel(channel)
            console.log("subscribed");
        })
        pusher.bind("client-new-message", (d) => {
            addMessage(d)
            console.log("pushhhhhhhhhh", d);
        })

        
        // pusher.bind("client-new-message", (d) => {
        //     addMessage(d)
        //     console.log("pushhhhhhhhhh", d);
        // })
    }, [])
    // console.log(myMessages);

    return (
        <Grid>
            <Grid.Column width={12} style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ height: '80vh', overflowY: 'scroll', display: "flex", flexDirection: "column-reverse", padding: 10 }}>
                    {myMessages.slice().reverse().map((m) => <Message key={m.id} isClient={!m.isMe} label={m.text} />)}

                </div>
                <Input onKeyUp={(e) => {
                    if(e.keyCode === 13){
                        if(inputData.trim()){
                            channel.trigger("client-new-message", {
                                message: inputData.trim(),
                                type: "pharma"
                            })

                            setMyMessages(old => [...old, {
                                id: Date.now().toString(),
                                text: inputData,
                                isMe: true,
                                date: new Date().toLocaleTimeString("fr-FR").substring(0, 5)
                            }])

                        }
                        setInputData("")
                    }
                }} icon={<Icon name="send" link disabled={!subscribed}
                    onClick={() => {
                        if(inputData.trim()){
                            channel.trigger("client-new-message", {
                                message: inputData.trim(),
                                type: "pharma"
                            })

                            setMyMessages(old => [...old, {
                                id: Date.now().toString(),
                                text: inputData,
                                isMe: true,
                                date: new Date().toLocaleTimeString("fr-FR").substring(0, 5)
                            }])

                        }
                        setInputData("")

                    }} />} value={inputData} onChange={({ target }) => setInputData(target.value)} placeholder='Saisissez un message...' size="big" />

            </Grid.Column>
            <Grid.Column style={{ backgroundColor: "#F1F2F4" }} width={4}>
                <ChatClient />
                <ChatClient />
                <ChatClient />
                <ChatClient />
                <ChatClient />
                <ChatClient />
                <ChatClient />

                {/* {myMessages.map(m =>  <ChatClient key={m.id} message /> )} */}

            </Grid.Column>
        </Grid>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setActualPath: (val) => dispatch(setActualPath(val))
    }
}
export default connect(null, mapDispatchToProps)(withRouter(Chat));
import React from 'react';
import Commande from "./Commande"
import ScrollArea from "react-scrollbar"
import { Button, Grid } from 'semantic-ui-react';
import CustomModal from '../CustomModal/CustomModal';

const ListeNouvellesCommandes = ({ nouvellesCommandes }) => {

    const items = nouvellesCommandes
    return (

        <>
            <ScrollArea
                speed={0.8}
                className="area"
                contentClassName="content"
                horizontal={false}
                style={{ height: 600, marginTop: 50, backgroundColor: " #e2e1e0", width: "98%" }}
            >

                {items.map((item, i) => <Commande
                    code={item.code}
                    idUser={item.idUser}
                    key={i}

                    middle={
                        <>
                          
                                <CustomModal
                                    idCommande={item._id}
                                    urlImage={item.image}
                                    code={item.code}
                                    trigger={<Button style={{ backgroundColor: "#46C531", color: "white" }} size="big" >Voir la commande</Button>}
                                />
                                

                                <Button style={{ backgroundColor: "#18A0FB", color: "white" }}  size="big">Indisponible</Button>
                        </>
                    }

                    right={
                        <p style={{ fontSize: 20, fontWeight: "bold", color: "#5A5A5A", textAlign: "center" }}>10:23</p>
                    }
                />)}
            </ScrollArea>
        </>


    );
};

export default ListeNouvellesCommandes;
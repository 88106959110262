import { SET_NOTIFICATION } from "../actions/notification.types";

const initialState={
    isNotified : false
}


const NotificationReducer = (state=initialState,action) => {
    state = {...state}

    switch (action.type) {
        case SET_NOTIFICATION:
            state.isNotified = action.payload
            break;
    
        default:
            break;
    }



    return state
}

export default NotificationReducer;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css'
import {createStore, combineReducers, applyMiddleware} from "redux"
import {Provider} from "react-redux"
import ActualPathReducer from './reducers/ActualPathReducer';
import NotificationReducer from './reducers/NotificationReducer';
import AppReducer from './reducers/AppReducer';
import createSagaMiddleware from "redux-saga"
import AuthReducer from './reducers/AuthReducer';
import nouvellesCommandesReducer from './reducers/NouvellesCommandesReducer';
import rootSaga from './sagas/root.sagas';
import ListNewOrdersReducer from './reducers/ListNewOrdersReducer';


const sagaMiddleware = createSagaMiddleware();


const reducers = combineReducers({
  apr: ActualPathReducer,
  nr: NotificationReducer,
  ar: AppReducer,
  aur: AuthReducer,
  ncr: nouvellesCommandesReducer,
  lnor: ListNewOrdersReducer
})
let store = createStore(reducers,applyMiddleware(sagaMiddleware))

sagaMiddleware.run(rootSaga);


ReactDOM.render(
  <Provider store={store}>
        {/* <React.StrictMode> */}
          <App />
      {/* </React.StrictMode> */}
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

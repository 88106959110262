import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGIN } from "./auth.types"
import {put, takeEvery} from "redux-saga/effects"
import axios from "axios"

const loginRequest = () => {
    return {
        type: LOGIN_REQUEST
    }
}

const loginSuccess = (val) => {
    return {
        type: LOGIN_SUCCESS,
        payload: val
    }
}

const loginFailure = (val) => {
    return {
        type: LOGIN_FAILURE,
        payload: val
    }
}




export function* executeLogin(action) {
    try{
        yield put(loginRequest());
        const result = yield axios.post("https://service-pharmacien-wergu-hk6w9.ondigitalocean.app/pharmacien/login",action.payload)
        yield put(loginSuccess(result.data))
    }catch(e){
        console.log(e.response);

        yield put(loginFailure(e.message))
    }
}


export function* authWatcher() {
    yield takeEvery(LOGIN,executeLogin)
}

import React, { useEffect } from 'react';
import ListeEnTraitement from '../components/ListeCommandes/ListeEnTraitement';
import {connect} from 'react-redux'
import {setActualPath} from "../actions/actualPath.actions"
import { withRouter } from 'react-router-dom';
import { useState } from 'react';
import { Loader } from 'semantic-ui-react';

const EnTraitement = ({history,setActualPath, pharmacien, socket}) => {
    let [loading, setLoading]=useState(false)
    let [listInProcess, setListInProcess] = useState([])
    useEffect(() => {
        setLoading(true)
        socket.emit("get_commands_in_process",{idPharmacie:pharmacien.idPharmacie},async(response) => {
            setLoading(false)
           setListInProcess(response)
        })
    },[pharmacien,socket])

    let {pathname} = history.location
    useEffect(() => {
        setActualPath(pathname)
    },[pathname,setActualPath])

    return (
        <div style={{width: "100%"}}>
                    {loading && <Loader content='Chargement des commandes en traitement' style={{fontSize: 30}} size="large" active/>}

            <ListeEnTraitement enTraitement={listInProcess}/>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return{
        setActualPath: (val) => dispatch(setActualPath(val))
    }
}


const mapStateToProps = state => {
    return {
        socket: state.ar.socket,
        newOrders: state.lnor.listNewOrders,
        searched: state.lnor.searched,
        pharmacien: state.ar.pharmacien
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(EnTraitement));